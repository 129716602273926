import * as React from "react";
import { Link } from "gatsby";

// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";
import { graphql } from "gatsby";

export default function RichGirl({ data }) {
  return (
    <Layout>
      <Helmet title="Rich Girl | Hall & Oates" />

      <h1>Rich Girl</h1>

      <h2>Hall & Oates</h2>

      <h4>Key: F#m</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Img fluid={data.file.childImageSharp.fluid} />

      <Columns columns="2">
        <Chorus>
          <p>You're A Rich Girl, And You've Gone Too Far</p>
          <p>'Cause You Know It Don't Matter Anyway</p>
          <p>You Can Rely On The Old Man's Money</p>
          <p>You Can Rely On The Old Man's Money</p>
          <p>It's A Bitch Girl But It's Gone Too Far</p>
          <p>'Cause You Know It Don't Matter Anyway</p>
          <p>Say Money But It Won't Get You Too Far,</p>
          <p>Get You Too Far</p>
        </Chorus>
        <Verse>
          <p>And Don't You Know, Don't You Know</p>
          <p>That It's Wrong To Take What Is Given You</p>
          <p>So Far Gone, On Your Own</p>
          <p>You Can Get Along If You Try To Be Strong</p>
          <p>But You'll Never Be Strong</p>
          <p>'Cause</p>
        </Verse>
        <Chorus>
          <p>You're A Rich Girl, And You've Gone Too Far</p>
          <p>'Cause You Know It Don't Matter Anyway</p>
          <p>You Can Rely On The Old Man's Money</p>
          <p>You Can Rely On The Old Man's Money</p>
          <p>It's A Bitch Girl But It's Gone Too Far</p>
          <p>'Cause You Know It Don't Matter Anyway</p>
          <p>Say Money But It Won't Get You Too Far,</p>
          <p>Get You Too Far</p>
        </Chorus>
        <Verse>
          <p>High And Dry, Out Of The Rain</p>
          <p>It's So Easy To Hurt Others When You Can't Feel Pain</p>
          <p>And Don't You Know That A Love Can't Grow</p>
          <p>'Cause There's Too Much To Give, 'Cause You'd Rather Live</p>
          <p>For The Thrill Of It All, Oh</p>
        </Verse>
        <Chorus>
          <p>You're A Rich Girl, And You've Gone Too Far</p>
          <p>'Cause You Know It Don't Matter Anyway</p>
          <p>You Can Rely On The Old Man's Money</p>
          <p>You Can Rely On The Old Man's Money</p>
          <p>It's A Bitch Girl But It's Gone Too Far</p>
          <p>'Cause You Know It Don't Matter Anyway</p>
          <p>Say Money But It Won't Get You Too Far,</p>
          <p>Get You Too Far</p>
        </Chorus>
        <Chorus>
          <p>And You Say</p>
          <p>You Can Rely On The Old Man's Money</p>
          <p>You Can Rely On The Old Man's Money</p>
          <p>You're A Rich Girl, A Rich Girl</p>
          <p>Oh, You're A Rich Bitch Girl Yeah</p>
          <p>Say Money But It Won't Get You Too Far,</p>
          <p>Oh, Get Ya Too Far</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}

export const query = graphql`
  query {
    file(relativePath: { eq: "rich-girl-f.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
